import {
  Button,
  Grid,
  FormControl,
  Typography,
  TextField,
} from "@mui/material";
import { useState, useCallback, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  fetchOccupanyPredictionDetail,
  addPullPredictedOccupancyData,
} from "../../apis/services/RevenueGeneration";
import Loader from "../../layout/Loader";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { fetchList } from "../../apis/services/CommonApiService";
import DialogForm from "../../shared/components/DialogForm";
import SpinnerGlobal from "../../layout/SpinnerGlobal";
import { toast } from "react-toastify";
import html2pdf from "html2pdf.js/dist/html2pdf.min.js";
import { DatePicker } from "@mui/x-date-pickers";

const OccupancyPrediction = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [ownHotelId, setOwnHotelId] = useState(null);
  const [addPullPredictedOccupancyDialog, setPullPredictedOccupancyDialog] =
    useState(false);
  const [searchFilters, setSearchFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().add(30, "days").format("YYYY-MM-DD"),
  });
  const [dateFilters, setDateFilter] = useState({
    startDate: moment().add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().add(8, "days").format("YYYY-MM-DD"),
  });

  const [chartData, setChartData] = useState({
    categories: [],
    series: [],
  });

  const componentRef = useRef();

  const fetchOccupanyPrediction = useCallback(async () => {
    setLoading(true);

    try {
      const queryParams = `?start_date=${searchFilters.startDate}&end_date=${searchFilters.endDate}`;

      const [predictedResponse, expectedResponse] = await Promise.all([
        fetchOccupanyPredictionDetail(queryParams),
        fetchList(ApiEndPoints.FORECAST_REPORT + queryParams),
      ]);
      if (
        predictedResponse?.status === 200 &&
        expectedResponse?.status === 200
      ) {
        // Remove the last element from expectedResponse.data
        const modifiedExpectedData = expectedResponse.data.slice(0, -1);

        setPredictionDataByDate(predictedResponse.data, modifiedExpectedData);
      }
    } catch (error) {
      console.error("Error fetching occupancy prediction data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [searchFilters]);

  const setPredictionDataByDate = async (predictedData, expectedData) => {
    // Extract dates and occupancies for predicted data
    const categories = predictedData.map((item) => item.date);
    const predictedOccupancyValues = predictedData.map(
      (item) => parseFloat(item.occupancy).toFixed(2) || 0
    );

    // Extract expected occupied rooms from the result1 data
    const expectedOccupancyValues = expectedData.map(
      (item) => parseFloat(item.expectedOccupancyPercentage).toFixed(2) || 0
    );
    setChartData({
      categories,
      series: [
        {
          name: t("Predicted Occupancy"),
          data: predictedOccupancyValues,
        },
        {
          name: t("Exact Occupancy"),
          data: expectedOccupancyValues,
        },
      ],
    });
  };

  useEffect(() => {
    fetchOccupanyPrediction();
    const items = JSON.parse(localStorage.getItem("userDetails"));
    if (items) {
      setOwnHotelId(items.hotel_id);
    }
  }, []);

  const chartOptions = {
    chart: {
      type: "line",
      height: 350,
    },
    series: chartData.series,
    xaxis: {
      categories: chartData.categories, // Dates for x-axis
      labels: {
        formatter: function (value) {
          return moment(value, "YYYY-MM-DD").format("MM/DD/YYYY"); // ✅ Format X-axis labels
        },
      },
    },
    title: {
      // text: t("Exact Occupancy vs Predicted Occupancy  by Date"),
      align: "center",
    },
    colors: ["#33C1FF", "#FF5733"], // Colors for the two lines
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  const handleAddPredictedRate = async () => {
    let diff = moment(dateFilters?.endDate).diff(
      dateFilters?.startDate,
      "days"
    );
    if (diff < 0) {
      toast.error("Please, Enter Correct End Date");
      return;
    }
    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.ADD_OCCUPANCY_PREDICTION +
        `?start_date=${dateFilters?.startDate}&end_date=${dateFilters?.endDate}&hotel_id=${ownHotelId}`
    );
    if (result?.status === 200) {
      toast.success(result?.message);
      fetchOccupanyPrediction();
      setPullPredictedOccupancyDialog(false);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(result?.message);
    }
    setLoading(false);
  };

  const handlePrint = async () => {
    if (componentRef.current) {
      setLoading(true);

      const options = {
        margin: [10, 10, 20, 10],
        html2canvas: {
          scale: 2, // Adjust scale for performance
          screenY: 0,
        },
        filename: `Revenue By Source-${searchFilters?.startDate || ""}-${
          searchFilters?.endDate || ""
        }.pdf`,
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
        image: { type: "jpeg", quality: 0.95 },
        pageBreak: { mode: ["avoid-all", "css"], avoid: ".pi-row" },
      };

      html2pdf()
        .set(options)
        .from(componentRef.current)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          const pageWidth = pdf.internal.pageSize.getWidth();
          const pageHeight = pdf.internal.pageSize.getHeight();
          const margin = 3;
          const title = `Exact Occupancy vs Predicted Occupancy: ${
            searchFilters?.startDate || ""
          } to ${searchFilters?.endDate || ""}`;
          const fontSize = 8;

          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(fontSize);
            const titleWidth = pdf.getTextWidth(title);
            pdf.text(title, (pageWidth - titleWidth) / 2, margin);
            pdf.text(`Page ${i} of ${totalPages}`, margin, pageHeight - margin);
          }

          const pdfBlob = pdf.output("blob");
          const blobURL = URL.createObjectURL(pdfBlob);

          const iframe = document.createElement("iframe");
          iframe.style.display = "none";
          iframe.src = blobURL;
          document.body.appendChild(iframe);

          iframe.contentWindow?.print();

          iframe.onload = () => {
            setTimeout(() => {
              document.body.removeChild(iframe);
              URL.revokeObjectURL(blobURL);
            }, 900000);
          };

          setLoading(false);
        });
    }
  };

  return (
    <div>
      <Grid container>
        {loading && <Loader />}

        <Grid
          container
          spacing={2}
          alignItems="flex-start"
          style={{ marginTop: "5px", marginBottom: "20px" }}
        >
          <Grid item xs={12} sm={6} md={4} style={{ paddingTop: "0px" }}>
            <FormControl fullWidth>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500 !important",
                }}
              >
                {t("Start Date")}
              </Typography>
              <DatePicker
                format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                value={moment(searchFilters.startDate, "YYYY-MM-DD")}
                onChange={(e) => {
                  setSearchFilters({
                    ...searchFilters,
                    startDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ paddingTop: "0px" }}>
            <FormControl fullWidth>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500 !important",
                }}
              >
                {t("End Date")}
              </Typography>
              <DatePicker
                format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                minDate={moment(searchFilters.startDate, "YYYY-MM-DD")} // Correct way to set minDate
                value={moment(searchFilters.endDate, "YYYY-MM-DD")}
                onChange={(e) => {
                  setSearchFilters({
                    ...searchFilters,
                    endDate: moment(e).format("YYYY-MM-DD"),
                  });
                }}
                slotProps={{
                  textField: {
                    readOnly: true,
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} style={{ paddingTop: "0px" }}>
            <div
              style={{
                height: "100%",
                marginTop: "40px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button
                size="large"
                variant="contained"
                disableElevation
                type="submit"
                color="primary"
                className="comman-button"
                onClick={fetchOccupanyPrediction}
              >
                {t("Submit")}
              </Button>

              <Button
                size="large"
                variant="contained"
                disableElevation
                color="primary"
                className="comman-button"
                onClick={() => setPullPredictedOccupancyDialog(true)}
              >
                {t("Pull Predicted Occupancy")}
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <Typography
              id="font-600"
              className="w-100"
              style={{ textAlign: "center" }}
            >
              {t("Exact Occupancy vs Predicted Occupancy by Date")}
            </Typography>
            <Button className="comman-button" onClick={() => handlePrint()}>
              {" "}
              {t("Print")}
            </Button>
          </div>
          <Chart
            options={chartOptions}
            series={chartOptions.series}
            type="line"
            height={400}
          />
        </Grid>
        <div
          style={{
            position: "absolute",
            right: "13000px",
            // display: "none"
          }}
        >
          <div ref={componentRef} style={{ minWidth: "700px" }}>
            <Typography id="font-600" style={{ textAlign: "center" }}>
              {t("Exact Occupancy vs Predicted Occupancy by Date")}
            </Typography>
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type="line"
              height={400}
            />
          </div>
        </div>
      </Grid>

      <DialogForm
        scroll="paper"
        maxWidth="xs"
        title={t("Add Pull Predicted Occupancy")}
        openDialog={addPullPredictedOccupancyDialog}
        handleDialogClose={() => setPullPredictedOccupancyDialog(false)}
        bodyContent={
          <div className="p-2" style={{ height: "auto" }}>
            {loading && <SpinnerGlobal />}

            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500 !important",
                    }}
                  >
                    {t("Start Date")}
                  </Typography>
                 
                  <DatePicker
                    format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                    minDate={moment().add(1, "days")}
                    value={moment(dateFilters?.startDate, "YYYY-MM-DD")}
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilters,
                        startDate: moment(e).format("YYYY-MM-DD"),
                        endDate:
                        moment(dateFilters?.endDate).isAfter(e)
                          ? dateFilters?.endDate
                            : moment(e).add(1, "days").format("YYYY-MM-DD")
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Typography
                    style={{
                      marginBottom: "11px",
                      color: "rgb(30, 30, 30)",
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "500 !important",
                    }}
                  >
                    {t("End Date")}
                  </Typography>
                  
                  <DatePicker
                    format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                    minDate={moment(dateFilters.startDate, "YYYY-MM-DD").add(1, "days")} // Correct way to set minDate
                    maxDate={moment(dateFilters.startDate, "YYYY-MM-DD").add(60, "days")}
                    value={moment(dateFilters.endDate, "YYYY-MM-DD")}
                    onChange={(e) => {
                      setDateFilter({
                        ...dateFilters,
                        endDate: moment(e).format("YYYY-MM-DD"),
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                      },
                    }}
                  />
                  <Grid item xs={12} className="mt-2 d-flex ">
                    <Button
                      onClick={handleAddPredictedRate}
                      className="comman-button"
                    >
                      {t("Submit")}
                    </Button>
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        }
      />
    </div>
  );
};

export default OccupancyPrediction;
