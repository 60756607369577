import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import DialogForm from "../../../shared/components/DialogForm";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useRef, useState } from "react";
import {
  createRecord,
  fetchList,
} from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import { toast } from "react-toastify";
import {
  suggestedHotelListFailed,
  suggestedHotelListSuccess,
} from "../../../Reducer/roomRateSlice";
import { connect } from "react-redux";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";

const AddCompetitorHotelDialog = (propsDia) => {
  const { t, i18n } = useTranslation();

  let {
    openAddHotelDialog,
    setOpenAddHotelDialog,
    suggestedHotelListSuccess,
    suggestedHotelListFailed,
  } = propsDia;

  const SampleList = [
    {
      competitor_hotel_name: "The Lenox Hotel, Boston, Massachusetts",
      competitor_hotel_id: "114134",
    },
    {
      competitor_hotel_name:
        "The Black Swan Inn Lee - Lenox, Ascend Hotel Collection, Lee, Massachusetts",
      competitor_hotel_id: "1146989",
    },
    {
      competitor_hotel_name:
        "The Lenox Guesthouse, Cape Town Central, South Africa",
      competitor_hotel_id: "4698434",
    },
    {
      competitor_hotel_name: "The Don CeSar, St. Pete Beach, Florida",
      competitor_hotel_id: "85782",
    },
    {
      competitor_hotel_name: "Enclave Di",
      competitor_hotel_id: "5",
    },
  ];

  const autocompleteRef = useRef(null);

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const [selectedHotel, setSelectedHotel] = useState({
    competitor_hotel_name: "",
    competitor_hotel_id: "",
  });

  const [searchHotelText, setSearchHotelText] = useState("");
  const [hotelListData, setHotelListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");

  const handleSelectAddress = async (selectedAddress, setFieldValue) => {
    try {
      // const results = await geocodeByAddress(selectedAddress);
      // const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);
      setSelectedHotel({
        competitor_hotel_name: selectedAddress,
        competitor_hotel_id: 0,
      })

      // // Extract city, state, country, and postal code from address components
      // results[0].address_components.forEach((component) => {
      //   // console.log(component, 171);

      //   if (component.types.includes("locality")) {
      //     setFieldValue("city_id", component.long_name);
      //   } else if (component.types.includes("administrative_area_level_1")) {
      //     setFieldValue("state_id", component.long_name);
      //   } else if (component.types.includes("country")) {
      //     setFieldValue("country_id", component.long_name);
      //   } else if (component.types.includes("postal_code")) {
      //     setFieldValue("postal_code", component.long_name);
      //   }
      // });

      // setFieldValue("address", selectedAddress);
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  const onSearchInputChangeHotel = (event) => {
    if (event === null) {
      setSearchHotelText("");
    } else {
      const { value } = event.target;
      if (value) {
        // console.log(value, 59)
        setSearchHotelText(value);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const getHotelList = async () => {
    // setHotelListData(SampleList);
    //       setOpen(true);

    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.GET_COMPETITOR_HOTEL_BY_SEARCH +
        `?search_text=${searchHotelText}`
    );
    if (result?.status === 200) {
      setHotelListData(
        result?.data?.map((item) => ({
          competitor_hotel_name: item?.name,
          competitor_hotel_id: item?.document_id,
        }))
      );
      setLoading(false);
      setOpen(true);
    } else {
      setLoading(false);
      toast.error(t(result?.message));
    }
  };

  const handleAddHotel = async () => {
    if(selectedHotel?.competitor_hotel_name === ""){
      toast.error("Please Select Hotel")
      return
    }
    setLoading(true);

    const result = await createRecord(
      selectedHotel,
      ApiEndPoints.ADD_SUGGESTED_HOTEL
    );
    if (result?.status === 200) {
      setLoading(false);
      toast.success(t(result?.message));
      getHotelListNew();
      setOpenAddHotelDialog(false);
    } else {
      setLoading(false);
      toast.error(t(result?.message));
      setOpenAddHotelDialog(false);
    }
  };

  const getHotelListNew = async () => {
    const result = await fetchList(
      ApiEndPoints.GET_SUGGESTED_HOTEL_LIST +
        `?page_no=${1}&items_per_page=${10}`
    );
    if (result?.status === 200) {
      suggestedHotelListSuccess(result.data.rows);
    } else {
      suggestedHotelListFailed();
    }
  };

  const searchOptions = {
    types: ["lodging"], // Filters results to only hotels
    // componentRestrictions: { country: "US" }, // Optional: Restrict results to a specific country
  };

  return (
    <DialogForm
      scroll="paper"
      // maxWidth="lg"
      maxWidth="md"
      title={t("Add Compeitor Hotel")}
      openDialog={openAddHotelDialog}
      handleDialogClose={() => setOpenAddHotelDialog(false)}
      bodyContent={
        <div className="p-2">
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item 
            // lg={8} md={8} 
            sm={12} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Search Hotel")}
              </Typography>
              {/* <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  options={hotelListData}
                  value={selectedHotel}
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  onInputChange={onSearchInputChangeHotel}
                  onChange={(event, value) => setSelectedHotel(value)}
                  getOptionLabel={(option) =>
                    option?.competitor_hotel_name || ""
                  }
                  PaperComponent={CustomPaper}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Search by hotel name")}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl> */}
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelectAddress}
                searchOptions={searchOptions}

              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: t("Search Hotels ..."),
                        className: "location-search-input",
                      })}
                      style={{
                        padding: "14px",
                        border: "1px solid #B4ADAD",
                        color: "#255480",
                        borderRadius: "6px",
                        width: "100%",
                        position: "relative",
                      }}
                    />
                    <div
                      className="autocomplete-dropdown-container"
                      style={{
                        position: "relative",
                        outline: "none",
                        zIndex: "1",
                        listStyle: "none",
                        padding: "0",
                        margin: "10px 0 10px -1px",
                        boxShadow: "1px 2px 18px rgba(0,0,0,0.25)",
                        backgroundColor: "white",
                        width: "100%",
                        maxHeight: "220px",
                        overflowY: "scroll",
                        borderRadius: "7px",
                      }}
                    >
                      {loading && (
                        <div
                          style={{
                            padding: "5px",
                            borderBottom: "1px solid #fafafa",
                          }}
                        >
                          {t("Loading...")}
                        </div>
                      )}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                              padding: "5px",
                              borderBottom: "1px solid #fafafa",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                              padding: "5px",
                              borderBottom: "1px solid #fafafa",
                            };
                        return (
                          <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </Grid>
            {/* <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              sx={{ top: { md: "48px" } }}
            >
              <Button
                variant="contained"
                disableElevation
                color="primary"
                className="comman-button ml-md-1"
                onClick={getHotelList}
              >
                {" "}
                {t("Search")}{" "}
              </Button>
            </Grid> */}
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              ></Typography>
            </Grid> */}
            <Grid item lg={12} sm={12} xs={12}>
              <Button
                className=" comman-button"
                size="large"
                variant="contained"
                onClick={handleAddHotel}
                disableElevation
                color="primary"
              >
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </div>
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    suggestedHotelList: state.roomRate.suggestedHotelList,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    suggestedHotelListSuccess: (data) =>
      dispatch(suggestedHotelListSuccess(data)),
    suggestedHotelListFailed: () => dispatch(suggestedHotelListFailed()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCompetitorHotelDialog);
