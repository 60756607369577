// import { useLocation } from 'react-router-dom';
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL, SCIENER_URL } from "../config/Urls";
import Storage from "../utils/HandelLocalStorage";
import { getHotelWiseToken, getToken } from "../utils/helper";

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 180000,
  params: {},
});

const sciener = axios.create({
  baseURL: SCIENER_URL,
  timeout: 18000,
  params: {},
});

// instance.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// instance.defaults.headers.post['Content-Type'] = 'application/json';
let token = "";
// let 
// Storage.retrieveItem("userDetails").then((params) => {
//   // const location = useLocation();
//   // console.log("Async User form axios", params);
//   // console.log("Async User form axios", location);
//   const data = JSON.parse(localStorage.getItem("userHotelsDetails"));
 
//   // console.log("Async User form axios", data);

//   if (params === null || params === undefined || params === "") {
//   }  else {
//     const userDetails = JSON.parse(params);
//     token = userDetails?.token;
//     // token = "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NywiZW1haWwiOiJ0YWphZG1pbkBnbWFpbC5jb20iLCJpYXQiOjE3MTQ5OTM1NTAsImV4cCI6MTc0NjUyOTU1MH0.X14ndM5uHN2Hzq3eecIUt6WN1nFatfK2G-8X1g2i-Sg";
 
//   }
//   if(data?.token){
//     token = data?.token;
//   }
// });
async function initializeToken() {
  const params = await getToken();
  const data = JSON.parse(localStorage.getItem("userHotelsDetails")) || {};
  
  token = params || data?.token || ""; // Prioritize correctly
}

// initializeToken();

console.log(token, 52)




// Add a request interceptor
instance.interceptors.request.use(
  async  function (config: any) {
    try {
      const params = await getToken();
      // const data = JSON.parse(localStorage.getItem("userHotelsDetails")) || {};
      const dataToken = await getHotelWiseToken();

      
      token = dataToken || params ||  ""; // Prioritize correctly
      const tokens = token; // Fetch token dynamically before each request
      // console.log(tokens, 61)
      if (tokens) {
        // config.headers.Authorization = `Bearer ${tokens}`;
        config.headers.Authorization = tokens;
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
    return config;
  },
  function (error: any) {
    // return Promise.reject(error);
    console.log("error from interceptor request", error);
    return Promise.reject(error);
    // return error;
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response: any) {
    // console.log('++++++++++Response Config++++++++++', response);
    return response;
  },
  function (error: any) {
    console.log("error from interceptor response", error);
    if (error.response.status === 400) {
      // toast.error(error.response.data.message);
      return error.response;
    } else {
      // toast.error(error.response.data.message);
      console.log('Error:', error.response.data.message);
    }

    return error.response ;
    // if (error) return error;
  }
);

export default instance;
