import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControlLabel,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Switch,
  styled,
  SwitchProps,
  Grid,
  OutlinedInput,
  InputLabel,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/material.css";
import { BookingTabs } from "../../shared/enums/new-booking";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";
import { Field, FieldArray, Formik } from "formik";
import moment from "moment";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GroupBookingAddInfoDialog from "./BookingDialog/GroupBookingAddInfoDialog";

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }} className="pb-0">
          {children}
        </Box>
      )}
    </div>
  );
}

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "rgb(28, 58, 102)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const Confirm = (props) => {
  // const navigate = useNavigate();
  const { t } = useTranslation();

  let {
    tabValue,
    bookingDetailsStats,
    bookingDetailsInitialState,
    handleClearBookings,
    bookRoomHandleSubmit,
    handleRoomNumber,
    handleRemoveRoomItem,
    paymentType,
    handalepaymentType,
    card_present,
    handaleCardPresent,
    handleGuaranteePolicy,
    guaranteePolicy,
    cancelPolicy,
    handleCancelPolicy,
    setCradsDetail,
    selectedCard,
    cardDetail,
    card,
    handeleCardChange,
    addCardDetail,
    handlePinpadDevice,
    pinpadDevicesList,
    onlyCard,
    handlePreviousTabChange,
    handleNextTabChange,
    setCoupenCode,
    coupenCode,
    handleCoupenApply,
    handleCoupenRemove,
    coupenCodeDetail,
    bookingType,
    searchUserResult,
    idProofTypes,
    onSearchInputChange,
    onSearchInputChangeTaxt,
    searchTaxResult,
    handleTaxSelected,
    taxRateData,
    setBookingDetails,
    setBookingDetailsStats,
    bookingDetails,
    exemptTaxData,
    isCorporateBooking,
    corporatePayment,
    setCorporatePayment,
  } = props;

  const [yearList, setYearList] = useState([]);
  const month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  useEffect(() => {
    let currentMonth = moment().format("YYYY");
    let yearArray = [];
    for (let i = 0; i < 100; i++) {
      yearArray.push((i + Number(currentMonth)).toString());
    }
    setYearList(yearArray);
  }, []);

  return (
    <TabPanel value={tabValue} index={BookingTabs.Confirm}>
      <>
        <Grid
          item
          md={12}
          sx={{
            position: "sticky",
            top: !onlyCard ? { sm: "75px", xs: "180px" } : "1px",
            zIndex: "1111",
          }}
        >
          <div
            style={{
              display: "flex",
              background: "#382DAE",
              border: "1px solid #F2F2F2",
              borderRadius: "6px",
              // width: "100%",
              height: "auto",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              padding: "10px 10px",
              // color: "#FFFFFF",
            }}
          >
            {tabValue !== BookingTabs.BookRoom ? (
              <ArrowBackIosIcon
                onClick={() => handlePreviousTabChange(tabValue)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffffff",
                  color: "#382DAE",
                  padding: "5px 0px 5px 10px",
                  borderRadius: "6px",
                  boxSizing: "content-box",
                }}
              />
            ) : (
              <div></div>
            )}
            <div
              style={{
                color: "#ffffff",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {t("Booking details")}: {bookingDetailsStats.noOfRooms}{" "}
              {t("room(s)")} | {bookingDetailsStats.noOfAdults} {t("adult(s)")},{" "}
              {bookingDetailsStats.noOfChild} {t("child(ren)")} | $
              {Number(bookingDetailsStats.finslTotal).toFixed(2)} |{" "}
              {/* <span style={{ color: "#2499D1" }}>Change</span> |{" "} */}
              {Number(bookingDetailsStats.noOfRooms) > 0 && (
                <span
                  onClick={handleClearBookings}
                  style={{ color: "#DD4B39", cursor: "pointer" }}
                >
                  {t("Clear")}
                </span>
              )}
            </div>
            {tabValue !== BookingTabs.Confirm ? (
              <ArrowForwardIosIcon
                onClick={() => handleNextTabChange(tabValue)}
                style={{
                  cursor: "pointer",
                  backgroundColor: "#ffffff",
                  color: "#382DAE",
                  padding: "5px",
                  borderRadius: "6px",
                  boxSizing: "content-box",
                }}
              />
            ) : (
              <div></div>
            )}
          </div>
        </Grid>
        <Formik
          initialValues={bookingDetailsInitialState}
          onSubmit={bookRoomHandleSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          // validationSchema={BookRoomsValidator}
        >
          {(props) => {
            const { handleSubmit } = props;
            // console.log(props.values, 278);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container>
                  <FieldArray name="rooms_info">
                    {({ push, remove }) => (
                      <Grid
                        container
                        //   spacing={2}
                        sx={{ marginTop: 2 }}
                      >
                        {props.values?.bookingDetailsInfo?.map(
                          (data: any, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  marginBottom: "15px",
                                  borderTop: "2px solid",
                                  marginTop: "15px",
                                }}
                              ></div>
                              <Grid
                                container
                                spacing={2}
                                style={{
                                  // width: "90%",
                                  // borderRight: "1px solid",
                                  bottom: "-15px",
                                  marginLeft: "-10px",
                                }}
                                id="BookingContainer"
                              >
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Room Type")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.roomType}
                                  </Typography>
                                </Grid>

                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Dates")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {moment(data.startDate).format("ll")} -{" "}
                                    {moment(data.endDate).format("ll")}
                                  </Typography>
                                </Grid>

                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Nights")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.nights}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Room rate")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.roomRateName}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Add On")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.withMeal.length > 0
                                      ? data.withMeal
                                          .map((item) => item.name)
                                          .join(", ")
                                      : t("NO Add On")}
                                  </Typography>
                                </Grid>
                                {!onlyCard && (
                                  <Grid item lg={2} md={4} sm={6} xs={12}>
                                    <Typography
                                      id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "#8A92A6",
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                      }}
                                    >
                                      {t("Discounted Rate")}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                      {data.discounted_rate
                                        ? data.discounted_rate
                                        : t("NO Discount Rate")}
                                    </Typography>
                                  </Grid>
                                )}

                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Cancellation policy")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.cancelationPolicy}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Quantity")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.roomQty}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Avg. Price/Room")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.AvgPricePerRoom.toFixed(2)}
                                  </Typography>
                                </Grid>

                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Add On Price")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.addOnPrice.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Extra Adult")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.noOfAdult}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Extra Child")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.noOfChild}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Extra Adult Price")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.discountAdult.toFixed(2)}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Extra Child Price")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.discountChild.toFixed(2)}
                                  </Typography>
                                </Grid>
                                {/* {!onlyCard && ( */}
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Discount")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.discountOnePrice.toFixed(2)}
                                  </Typography>
                                </Grid>
                                {/* )} */}
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Total Price")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.discountedTotalPrice}
                                  </Typography>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                  <Typography
                                    id="font-600"
                                    style={{
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Total Price With Tax")}
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {data.discountedTotalPriceWithTax}
                                  </Typography>
                                </Grid>

                                <Grid
                                  item
                                  lg={2}
                                  md={4}
                                  sm={6}
                                  xs={12}
                                  style={{ paddingRight: "5px" }}
                                >
                                  <Typography
                                    id="font-600"
                                    style={{
                                      // marginBottom: "11px",
                                      color: "#8A92A6",
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    }}
                                  >
                                    {t("Room Number")}
                                  </Typography>
                                  <Field
                                    name={`bookingDetailsInfo.${index}.roomNo`}
                                    onChange={(e) => {
                                      handleRoomNumber(
                                        e,
                                        props.setFieldValue,
                                        index,
                                        data
                                      );
                                    }}
                                    defaultOption
                                    options={data?.availableRooms?.map(
                                      (item, index) => ({
                                        title: item?.room_name,
                                        value: item?.id,
                                      })
                                    )}
                                    component={FormikSelect}
                                  />
                                </Grid>
                                {bookingType === 2 && (
                                  <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}
                                    style={{ paddingRight: "5px" }}
                                  >
                                    {t("Guest")}:{" "}
                                    <Button
                                      sx={{
                                        border: "1px solid black",
                                        color: "black",
                                      }}
                                      onClick={() =>
                                        props.setFieldValue(
                                          `bookingDetailsInfo.${index}.guestDialog`,
                                          true
                                        )
                                      }
                                    >
                                      {props.values?.bookingDetailsInfo[index]
                                        ?.primaryGuest === 1
                                        ? t("Primary")
                                        : props.values?.bookingDetailsInfo[
                                            index
                                          ].groupGuestInfo?.name || t("Change")}
                                    </Button>
                                    <GroupBookingAddInfoDialog
                                      props={props}
                                      idProofTypes={idProofTypes}
                                      index={index}
                                      bookingDetailsInitialState={
                                        bookingDetailsInitialState
                                      }
                                      onSearchInputChangeTaxt={
                                        onSearchInputChangeTaxt
                                      }
                                      searchTaxResult={searchTaxResult}
                                      handleTaxSelected={handleTaxSelected}
                                      taxRateData={taxRateData}
                                      setBookingDetails={setBookingDetails}
                                      setBookingDetailsStats={
                                        setBookingDetailsStats
                                      }
                                      bookingDetails={bookingDetails}
                                      bookingDetailsStats={bookingDetailsStats}
                                      exemptTaxData={exemptTaxData}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                              <Grid
                                container
                                spacing={2}
                                style={{ marginBottom: "30px" }}
                                id={"BookinButtonContainer"}
                              >
                                <Button
                                  // style={{ left: "91%" }}
                                  variant="contained"
                                  onClick={() =>
                                    handleRemoveRoomItem(data, index)
                                  }
                                >
                                  {t("Remove")}
                                </Button>
                              </Grid>
                            </div>
                          )
                        )}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </form>
            );
          }}
        </Formik>

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "15px",
            borderTop: "2px solid",
            marginTop: "15px",
          }}
        ></div>
        {onlyCard && (
          <Grid
            container
            spacing={2}
            className="mb-2"
            // style={{ marginLeft: "0px" }}
          >
            <Grid item lg={3} md={3} sm={8} xs={8}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Coupon Code")}
              </Typography>

              <TextField
                value={coupenCode}
                className="text-uppercas"
                placeholder={t("Enter Coupon")}
                onChange={(e) => setCoupenCode((e.target?.value).toUpperCase())}
                name="Enter Coupon"
                id="group_name"
              />

              {coupenCodeDetail?.coupon_value && (
                <p
                  style={{
                    color: "green",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  {(+coupenCodeDetail?.coupon_value).toFixed(0)}% OFF For All
                  Rooms
                </p>
              )}
            </Grid>
            <Grid item lg={1.5} md={4} xs={4}>
              <Typography
                style={{
                  marginBottom: `${!onlyCard ? "11px" : ""}`,
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              ></Typography>
              {!coupenCodeDetail?.id ? (
                <Button
                  variant="contained"
                  onClick={handleCoupenApply}
                  title="Cancel"
                  className="comman-button"
                  style={{
                    // width: "150px",
                    // height: "47px",
                    marginTop: "40px",
                  }}
                >
                  {t("Apply")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleCoupenRemove}
                  title="Cancel"
                  className="comman-cancel-button"
                  style={{
                    // width: "150px",
                    // height: "47px",
                    marginTop: "40px",
                  }}
                >
                  {t("Remove")}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={2}
          // style={{ marginLeft: "0px" }}
        >
          {isCorporateBooking === 1 && (
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Payment to")}
              </Typography>
              <Select
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={corporatePayment}
                // label="Age"
                onChange={(e) => setCorporatePayment(e?.target?.value)}
              >
                <MenuItem value={0}>{t("Guest")}</MenuItem>
                <MenuItem value={1}>{t("Company")}</MenuItem>
              </Select>
            </Grid>
          )}
          {((isCorporateBooking === 1 && corporatePayment === 0) ||
            isCorporateBooking === 0) && (
            <Grid item lg={4} md={4} sm={3} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Payment Option")}
              </Typography>
              <Select
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={paymentType}
                // label="Age"
                onChange={(e) => handalepaymentType(e)}
              >
                {!onlyCard && <MenuItem value={1}>{t("Cash")}</MenuItem>}
                <MenuItem value={2}>{t("Credit Card")}</MenuItem>
                {!onlyCard && <MenuItem value={3}>{t("Cheque")}</MenuItem>}
                {!onlyCard && <MenuItem value={4}>{t("Direct Bill")}</MenuItem>}
              </Select>
            </Grid>
          )}
          {paymentType === 2 && !onlyCard && (
            <Grid item lg={2} md={2} sm={3} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Card Present")}
              </Typography>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    checked={card_present}
                    onChange={(e) => handaleCardPresent(e)}
                  />
                }
                label={""}
              />
            </Grid>
          )}

          {!onlyCard && (
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Guarantee Policy")}
              </Typography>
              <Select
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={guaranteePolicy}
                // label="Age"
                onChange={(e) => handleGuaranteePolicy(e)}
              >
                <MenuItem value={1}>{t("Prepayment required")}</MenuItem>
                <MenuItem value={2}>{t("No required")}</MenuItem>
              </Select>
            </Grid>
          )}
          {/* {!onlyCard && (
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <Typography
              id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Cancel Policy")}
              </Typography>
              <Select
                style={{ width: "100%" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cancelPolicy}
                // label="Age"
                onChange={(e) => handleCancelPolicy(e)}
              >
                <MenuItem value={1}>{t("Cancel")}</MenuItem>
                <MenuItem value={2}>{t("No Cancel")}</MenuItem>
              </Select>
            </Grid>
          )} */}
        </Grid>
        {card_present === false && paymentType === 2 && (
          <Grid
            container
            spacing={2}
            style={{
              marginLeft: "0px",

              border: "2px solid #B4ADAD",
              borderRadius: "5px",
              width: "100%",
              marginTop: "25px",
              paddingBottom: "10px",
              paddingRight: "16px",
              height: "fit-content",
            }}
          >
            {!onlyCard && (
              <Grid item lg={2} md={4} xs={12}>
                <Typography
                  id="font-600"
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("select Card")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    onChange={(e) => setCradsDetail(e)}
                    value={selectedCard}
                  >
                    <MenuItem value={0}>{t("Add Card")}</MenuItem>
                    {cardDetail.map((item) => (
                      <MenuItem value={item.id}>
                        {item.card_holder_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item lg={3} md={onlyCard ? 6 : 4} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Card Holder Name")}
              </Typography>
              <TextField
                value={card?.card_holder_name}
                disabled={
                  card?.id === 0 || Number.isNaN(card?.id) ? false : true
                }
                placeholder={t("Card Holder Name")}
                onChange={(e) => handeleCardChange(e)}
                name="card_holder_name"
                id="group_name"
              />
            </Grid>
            <Grid item lg={3} md={onlyCard ? 6 : 4} xs={12}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Card Number")}
              </Typography>
              <TextField
                value={card?.card_number}
                placeholder={t("Card Number")}
                disabled={
                  card?.id === 0 || Number.isNaN(card?.id) ? false : true
                }
                type={card?.id === 0 || Number.isNaN(card?.id) ? "number" : ""}
                name="card_number"
                onChange={(e) => handeleCardChange(e)}
                id="group_name"
              />
            </Grid>
            <Grid item lg={1} md={4} xs={6}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Expiry")}
              </Typography>
              {/* <TextField
                    value={card?.mm}
                    disabled={
                      card?.id === 0 || Number.isNaN(card?.id) ? false : true
                    }
                    placeholder={t("MM")}
                    onChange={(e) => handeleCardChange(e)}
                    name="mm"
                    id="contact_name"
                  /> */}
              <FormControl fullWidth>
                <InputLabel title={"Month"}>{"MM"}</InputLabel>
                <Select
                  name="mm"
                  input={<OutlinedInput label="MM" />}
                  label="Month"
                  disabled={
                    card?.id === 0 || Number.isNaN(card?.id) ? false : true
                  }
                  onChange={(e) => handeleCardChange(e)}
                  value={card?.mm}
                >
                  <MenuItem value={""}>{t("MM")}</MenuItem>
                  {month.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={1} md={4} xs={6}>
              <Typography
                style={{
                  marginBottom: "35px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {" "}
              </Typography>
              {/* <TextField
                    value={card?.yyy}
                    disabled={
                      card?.id === 0 || Number.isNaN(card?.id) ? false : true
                    }
                    placeholder={t("YYYY")}
                    onChange={(e) => handeleCardChange(e)}
                    name="yyy"
                    id="contact_name"
                  /> */}
              <FormControl fullWidth>
                <InputLabel title={"Year"}>{"YYYY"}</InputLabel>
                <Select
                  name="yyy"
                  input={<OutlinedInput label="YYYY" />}
                  label="Year"
                  disabled={
                    card?.id === 0 || Number.isNaN(card?.id) ? false : true
                  }
                  onChange={(e) => handeleCardChange(e)}
                  value={card?.yyy}
                >
                  <MenuItem value={""}>{t("YYYY")}</MenuItem>
                  {yearList.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {card?.id === 0 || Number.isNaN(card?.id) ? (
              <Grid item lg={1.8} md={4} xs={12}>
                <Typography
                  id="font-600"
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Security Code")}
                </Typography>
                <TextField
                  value={card?.cvv}
                  // disabled={card?.id === 0 || Number.isNaN(card?.id) ? false : true}
                  onChange={(e) => handeleCardChange(e)}
                  placeholder={t("CVV")}
                  name="cvv"
                  id="cvv"
                  // type="number"
                />
              </Grid>
            ) : (
              <Grid item lg={1.8} md={4} xs={12}>
                <Typography
                  id="font-600"
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Card Type")}
                </Typography>
                <TextField
                  value={card?.card_type}
                  disabled={
                    card?.id === 0 || Number.isNaN(card?.id) ? false : true
                  }
                  // onChange={(e) => handeleCardChange(e)}
                  placeholder={t("Card Type")}
                  name="Card Type"
                  id="Card Type"
                />
              </Grid>
            )}

            {card?.id === 0 || Number.isNaN(card?.id) ? (
              <Grid item lg={1.5} md={4} xs={12}>
                <Typography
                  style={{
                    marginBottom: `${!onlyCard ? "11px" : ""}`,
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                ></Typography>
                <Button
                  variant="contained"
                  onClick={addCardDetail}
                  title="Cancel"
                  className="comman-button"
                  style={{
                    // width: "150px",
                    // height: "47px",
                    marginTop: `${!onlyCard ? "-10px" : "36px"}`,
                  }}
                >
                  {t("Add & Verify")}
                </Button>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        )}

        {card_present === true && paymentType === 2 && (
          <Grid
            container
            spacing={2}
            style={{
              marginLeft: "35px",
              border: "2px solid #B4ADAD",
              borderRadius: "5px",
              width: "90%",
              marginTop: "25px",
              height: "135px",
            }}
          >
            <Grid item lg={4}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Pinpad Devices")}
              </Typography>
              <FormControl fullWidth>
                <Select
                  onChange={(e) => handlePinpadDevice(e)}
                  defaultValue={0}
                >
                  <MenuItem value={0}>{t("-- select --")}</MenuItem>
                  {pinpadDevicesList?.map((item) => (
                    <MenuItem value={item.device_id}>{item.device_id}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        {paymentType === 3 && (
          <Grid
            container
            spacing={2}
            style={{
              marginLeft: "20px",
              border: "2px solid #B4ADAD",
              borderRadius: "5px",
              width: "97%",
              marginTop: "20px",
              height: "120px",
            }}
          >
            <Grid item lg={4}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Cheque Number")}
              </Typography>
              <TextField
                label={t("Cheque Number")}
                name="group_name"
                id="group_name"
              />
            </Grid>
            <Grid item lg={4}>
              <Typography
                id="font-600"
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                }}
              >
                {t("Bank Name")}
              </Typography>
              <TextField
                label={t("Bank Name")}
                name="group_name"
                id="group_name"
              />
            </Grid>
          </Grid>
        )}

        <div
          className="w-100 mt-2 d-flex justify-content-end"
          style={{ flexFlow: "row wrap", flexShrink: "0" }}
        >
          <h5 className="mr-2 mt-1">
            {t("Room Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.totalAmount.toFixed(2)}
            </span>
          </h5>
          <h5 className="mr-2 mt-1">
            {t("Child Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.extraChild.toFixed(2)}
            </span>
          </h5>
          <h5 className="mr-2 mt-1">
            {t("Adult Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.extraAdult.toFixed(2)}
            </span>
          </h5>
          {/* {!onlyCard && ( */}
          <h5 className="mr-2 mt-1">
            {t("Discount Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.discountedTotal.toFixed(2)}
            </span>
          </h5>
          {/* )} */}
          <h5 className="mr-2 mt-1">
            {t("Add-On Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.addOnTotal.toFixed(2)}
            </span>
          </h5>
          <h5 className="mr-2 mt-1">
            {t("Sub Total")} :{" "}
            <span id="font-500">
              $
              {(
                Number(bookingDetailsStats.finslTotal) -
                Number(bookingDetailsStats.totalTax)
              ).toFixed(2)}
            </span>
          </h5>
          {!onlyCard && (
            <h5 className="mr-2 mt-1">
              {t("Exempt Tax Total")} :{" "}
              <span id="font-500">
                ${bookingDetailsStats.taxAmount.toFixed(2)}
              </span>
            </h5>
          )}
          <h5 className="mr-2 mt-1">
            {t("Tax Total")} :{" "}
            <span id="font-500">
              ${bookingDetailsStats.totalTax.toFixed(2)}
            </span>
          </h5>

          <h5 className="mr-2 mt-1">
            {t("Final Total")} :{" "}
            <span id="font-500">
              ${Number(bookingDetailsStats.finslTotal).toFixed(2)}
            </span>
          </h5>
        </div>
      </>
    </TabPanel>
  );
};

// const Confirm = () => {
//     const [alignment, setAlignment] = React.useState('left');

//   const handleChange = (event, newAlignment) => {
//     setAlignment(newAlignment);
//   };

//   const tableHeading = [
//     {
//       name: "Room Type",
//     },
//     {
//       name: "Dates",
//     },
//     {
//       name: "Nights",
//     },
//     {
//       name: "Room rate",
//     },
//     {
//       name: "Meals",
//     },
//     {
//       name: "Cancellation policy",
//     },
//     {
//       name: "Price for room",
//     },
//     {
//       name: "Room No",
//     },
//     {
//       name: "Guests",
//     },
//     {
//       name: "",
//     },
//   ];

//   const data = [
//     {
//       Booking: "King",
//       Room: "Oct 28, 2022 - Oct 30, 2022",
//       Guests: "2",
//       Chat: "Standard",
//       Nights: "No meals",
//       Roomrate: "Free cancellation policy",
//       Cancellationpolicy: "$200.00",
//       Priceforroom: "1",
//       RoomNo: "2",
//       Guests: "2",
//     },
//   ];

//   const [checked, setChecked] = React.useState(true);

//   const handleChecked = (event) => {
//     setChecked(event.target.checked);
//   };

//   return (
//     <>
//         <Grid item xl={12}>
//             <div
//                 style={{
//                 background: "#F2F2F2",
//                 border: "1px solid #F2F2F2",
//                 borderRadius: "6px",
//                 width: "100%",
//                 height: "49px",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 display: "flex",
//                 }}
//             >
//                 <h1
//                 style={{
//                     color: "#1C3A66",
//                     fontSize: "18px",
//                     fontWeight: "500",
//                 }}
//                 >
//                 Booking details: 0 room(s) | 0 adult(s), 0 child(ren) |
//                 €00.00 | <span style={{ color: "#2499D1" }}>Change</span> |{" "}
//                 <span style={{ color: "#DD4B39" }}>Clear</span>
//                 </h1>
//             </div>
//         </Grid>

//         <div className="w-100 mt-2">
//             <div className='pl-3 pr-3'>
//                 <TableContainer>
//                     <Table>
//                         <TableHead>
//                             <TableRow>
//                             {/* <TableCell key="Action" className="text-center" width="50">
//                                 <Checkbox />
//                             </TableCell> */}
//                             {tableHeading.map((heading) => {
//                                 return (
//                                 <>
//                                     <TableCell
//                                     key={heading.name}
//                                     className="text-center"
//                                     maxWidth="10"
//                                     >
//                                     {heading.name}
//                                     </TableCell>
//                                 </>
//                                 );
//                             })}
//                             </TableRow>
//                         </TableHead>
//                         <TableBody>
//                             <TableRow>
//                                 {data.map((data) => {
//                                     return(
//                                     <>
//                                         <TableCell  className="text-center">{data.Booking}</TableCell>
//                                         <TableCell  className="text-center">{data.Room}</TableCell>
//                                         <TableCell  className="text-center">{data.Guests}</TableCell>
//                                         <TableCell  className="text-center">{data.Chat}</TableCell>
//                                         <TableCell  className="text-center">{data.Nights}</TableCell>
//                                         <TableCell  className="text-center">{data.Roomrate}</TableCell>
//                                         <TableCell  className="text-center">{data.Cancellationpolicy}</TableCell>
//                                         <TableCell  className="text-center">{data.Priceforroom}</TableCell>
//                                         <TableCell  className="text-center">{data.RoomNo}</TableCell>
//                                         <TableCell  className="text-center">{data.Guests}</TableCell>

//                                     </>
//                                     )
//                                 })}
//                             </TableRow>
//                         </TableBody>
//                     </Table>
//                 </TableContainer>
//             </div>
//         </div>
//         <div className='w-100 mt-2 d-flex justify-content-end'>
//            <h5 className='mr-2 mt-1'>Total $200.00</h5>
//         </div>

//         <div className='w-100 mt-2 ml-2'>
//           <FormControl>
//             <FormLabel id="demo-row-radio-buttons-group-label">Payment Method</FormLabel>
//               <RadioGroup
//                 row
//                 aria-labelledby="demo-row-radio-buttons-group-label"
//                 name="row-radio-buttons-group"
//                 >
//                 <div style={{width:"300px", background:"white", border:"1px solid black", borderRadius:'5px', display:"flex",justifyContent:"space-between", marginTop:"5px", marginRight:"30px"}}>
//                   <div style={{minWidth:"150px", padding:"10px", display:"flex"}}>
//                     <img style={{marginRight:"10px"}} src={mastercard}/>
//                     <div>
//                     <div>
//                      •••• •••• •••• 2309
//                     </div>
//                     <div>
//                      Expires 10/24
//                     </div>
//                     </div>
//                   </div>
//                   <div style={{paddingTop:"10px"}}><FormControlLabel value="1" control={<Radio />} label="" /></div>
//                 </div>
//                 <div style={{width:"300px", background:"white", border:"1px solid black", borderRadius:'5px', display:"flex",justifyContent:"space-between", marginTop:"5px", marginRight:"30px"}}>
//                   <div style={{minWidth:"150px", padding:"10px", display:"flex"}}>
//                     <img style={{marginRight:"10px"}} src={paypal}/>
//                     <div>
//                     <div>
//                     Johan Doe
//                     </div>
//                     <div>
//                     johan09@gmail.com
//                     </div>
//                     </div>
//                   </div>
//                   <div style={{paddingTop:"10px"}}><FormControlLabel value="2" control={<Radio />} label="" /></div>
//                 </div>
//                 <div style={{width:"300px", background:"white", border:"1px solid black", borderRadius:'5px', display:"flex",justifyContent:"space-between", marginTop:"5px", marginRight:"30px"}}>
//                   <div style={{minWidth:"150px", padding:"10px", display:"flex"}}>
//                     <img style={{marginRight:"10px"}} src={mastercard}/>
//                     <div>
//                     <div>
//                      •••• •••• •••• 2309
//                     </div>
//                     <div>
//                      Expires 10/24
//                     </div>
//                     </div>
//                   </div>
//                   <div style={{paddingTop:"10px"}}><FormControlLabel value="3" control={<Radio />} label="" /></div>
//                 </div>
//                 <div style={{width:"300px", background:"white", border:"1px solid black", borderRadius:'5px', display:"flex",justifyContent:"space-between", marginTop:"5px", marginRight:"30px"}}>
//                   <div style={{minWidth:"150px", padding:"10px", display:"flex"}}>
//                     <img style={{marginRight:"10px"}} src={paypal}/>
//                     <div>
//                     <div>
//                     MetaMask
//                     </div>
//                     <div>
//                     johan09@gmail.com
//                     </div>
//                     </div>
//                   </div>
//                   <div style={{paddingTop:"10px"}}><FormControlLabel value="4" control={<Radio />} label="" /></div>
//                 </div>
//                 <div style={{width:"300px", background:"white", border:"1px solid black", borderRadius:'5px', display:"flex",justifyContent:"space-between", marginTop:"30px", marginRight:"30px"}}>
//                   <div style={{minWidth:"150px", padding:"10px", display:"flex"}}>
//                     <img style={{marginRight:"10px"}} src={mastercard}/>
//                     <div>
//                     <div>
//                      •••• •••• •••• 2309
//                     </div>
//                     <div>
//                      Expires 10/24
//                     </div>
//                     </div>
//                   </div>
//                   <div style={{paddingTop:"10px"}}><FormControlLabel value="5" control={<Radio />} label="" /></div>
//                 </div>
//               </RadioGroup>
//           </FormControl>
//         </div>
// </>
//   )
// }

// export default Confirm
