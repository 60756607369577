import DialogForm from "../../../shared/components/DialogForm";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { fetchList } from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import { toast } from "react-toastify";
import {
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import Loader from "../../../layout/Loader";
import { DatePicker } from "@mui/x-date-pickers";

const CompetitorHotelRatesDialog = (propsDia) => {
  const { t, i18n } = useTranslation();

  let { openHotelRatesDialog, setOpenHotelRatesDialog, hotelId, hotelName } =
    propsDia;

  const [loading, setLoading] = useState(false);
  const [hotelListRate, setHotelListRate] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    startDate: moment().add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().add(2, "days").format("YYYY-MM-DD"),
    noOfGuest: 2,
    rooms: 1,
  });

  const getHotelRate = async () => {
    // setHotelListData(SampleList);
    //       setOpen(true);

    setLoading(true);
    const result = await fetchList(
      ApiEndPoints.GET_COMPETITOR_HOTEL_RATE +
        `?checkin=${searchFilters?.startDate}&checkout=${searchFilters?.endDate}&rooms=${searchFilters?.rooms}&adults=${searchFilters?.noOfGuest}&hotelid=${hotelId}`
    );
    if (result?.status === 200) {
      let array = [];
      // for (let i in result?.data?.comparison) {
      //   for (let j in result?.data?.comparison[i]) {
      //     let obj = result?.data?.comparison[i][j];
      //     // console.log(obj[`vendor${+j+1}`],j, 44)
      //     array.push({
      //       vendor: obj[`vendor${+j + 1}`],
      //       price: obj[`price${+j + 1}`],
      //       tax: obj[`tax${+j + 1}`],
      //     });
      //   }
      // }
      setHotelListRate(result?.data?.properties);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(t(result?.message));
    }
  };

  // console.log(hotelListRate,59)

  const handleCheckOutDate = (event) => {
    const { value } = event.target;
    // setEndDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      endDate: moment(value).format("YYYY-MM-DD"),
    }));
  };

  const handleCheckInDate = (event) => {
    const { value } = event.target;
    // setStartDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      startDate: moment(value).format("YYYY-MM-DD"),
      endDate: moment(moment(value).add({ days: 1 })).format("YYYY-MM-DD"),
    }));
  };

  const handleGuestChange = (event) => {
    const { value } = event.target;
    // setNoOfGuest(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      noOfGuest: value,
    }));
  };

  const handleRoomChange = (event) => {
    const { value } = event.target;
    // setNoOfGuest(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      rooms: value,
    }));
  };

  return (
    <DialogForm
      scroll="paper"
      maxWidth="lg"
      // maxWidth="md"
      title={`${t("View Hotel Rates")} (${hotelName})`}
      openDialog={openHotelRatesDialog}
      handleDialogClose={() => {
        setOpenHotelRatesDialog(false);
        setHotelListRate([]);
      }}
      bodyContent={
        <div className="p-2">
          {loading && <Loader />}
          <Grid container spacing={2} className="mb-2">
            <Grid item lg={4} md={6} xs={12}>
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                  }}
                >
                  {t("Check In Date")}
                </Typography>

                <DatePicker
                  format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                  minDate={moment(
                    moment().add({
                      days: 1,
                    })
                  )} // Correct way to set minDate
                  value={moment(searchFilters.startDate, "YYYY-MM-DD")}
                  onChange={(e) => {
                    handleCheckInDate({
                      target: { value: e },
                    });
                  }}
                  slotProps={{
                    textField: { inputProps: { readOnly: true } },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                  }}
                >
                  {t("Check Out Date")}
                </Typography>
               
                <DatePicker
                  format="MM/DD/YYYY" // Ensures MM/DD/YYYY format
                  minDate={moment(
                    moment(searchFilters.startDate, "YYYY-MM-DD").add({
                      days: 1,
                    })
                  )} // Correct way to set minDate
                  value={moment(searchFilters.endDate, "YYYY-MM-DD")}
                  onChange={(e) => {
                    handleCheckOutDate({
                      target: { value: e },
                    });
                  }}
                  slotProps={{
                    textField: { inputProps: { readOnly: true } },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                  }}
                >
                  {t("Adults")}
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={1}
                  onChange={handleGuestChange}
                  value={searchFilters?.noOfGuest}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <FormControl fullWidth>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 500,
                  }}
                >
                  {t("Rooms")}
                </Typography>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={1}
                  onChange={handleRoomChange}
                  value={searchFilters?.rooms}
                >
                  {Array.from({ length: 10 }, (_, i) => i + 1).map((data) => (
                    <MenuItem key={data} value={data}>
                      {data}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                className="comman-button ml-1 mt-md-4"
                onClick={getHotelRate}
              >
                {" "}
                {t("Submit")}{" "}
              </Button>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider />
              <div className="d-flex mt-2 gap-2 align-items-center flex-wrap">
                <div
                  style={{ gap: "10px" }}
                  className="d-flex  align-items-center"
                >
                  <Typography id="font-600">{t("Hotel Name : ")}</Typography>
                  <Typography>
                    {hotelListRate?.length > 0
                      ? hotelListRate[0]?.name || ""
                      : ""}
                  </Typography>
                </div>
                <div
                  style={{ gap: "10px" }}
                  className="d-flex  align-items-center"
                >
                  <Typography id="font-600">{t("Rate : ")}</Typography>
                  <Typography>
                    {hotelListRate?.length > 0 &&
                    hotelListRate[0]?.rate_per_night
                      ? hotelListRate[0]?.rate_per_night?.extracted_lowest || ""
                      : ""}
                  </Typography>
                </div>
              </div>
              {/* <Table>
                <TableHead>
                  <TableRow>
                    <>
                      <TableCell className="text-center" width="50">
                        <Typography variant="h4"> {t("OTA")}</Typography>
                      </TableCell>
                      <TableCell className="text-center" width="50">
                        <Typography variant="h4"> {t("Rate")}</Typography>
                      </TableCell>
                      <TableCell className="text-center" width="50">
                        <Typography variant="h4"> {t("Tax")}</Typography>
                      </TableCell>
                    </>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {hotelListRate?.map((item, it) => {
                    return (
                      <TableRow key={it}>
                        <>
                          <TableCell className="text-center" width="50">
                            {item[`vendor`]}
                          </TableCell>
                          <TableCell className="text-center" width="50">
                            {item[`price`]}
                          </TableCell>
                          <TableCell
                            className="text-center"
                            width="50"
                            //   style={{
                            //     wordWrap: "break-word",
                            //     whiteSpace: "normal",
                            //   }}
                          >
                            {item[`tax`]}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table> */}
            </Grid>
          </Grid>
        </div>
      }
    />
  );
};

export default CompetitorHotelRatesDialog;
