import * as Yup from "yup";

export const CreateRoomTypeValidator = (t) =>
  Yup.object().shape({
    category_name: Yup.string().required(t("Name cannot be blank.")),
    // room_type: Yup.number().required("Type cannot be blank."),
    area: Yup.number().optional(),
    min_rate: Yup.number()
      .min(0, t("Please enter more then 1"))
      .required(t("Min Rate cannot be blank.")),
    max_rate: Yup.number()
      .min(1, t("Please enter more then 1"))
      .required(t("Max Rate cannot be blank."))
      .test(
        "max-rate-greater-than-min-rate",
        t("Max Rate must be greater than or equal to Min Rate."),
        function (value) {
          const { min_rate } = this.parent; // Access the value of min_rate from the same object
          return value >= min_rate; // Ensure max_rate is greater than or equal to min_rate
        }
      ),
    max_occupancy: Yup.number()
      .required(t("Max occupancy cannot be blank."))
      .test(
        "max-occupancy-greater-than-default",
        t("Max occupancy must be greater than or equal to default occupancy."),
        function (value) {
          const { default_occupancy } = this.parent; // Access default_occupancy
          return value >= default_occupancy; // Ensure max_occupancy is greater than or equal to default_occupancy
        }
      ),
    default_occupancy: Yup.number().required(
      t("Min occupancy cannot be blank.")
    ),
    // max_adult: Yup.number()
    //   .optional()
    //   .test(
    //     "max-adult-plus-max-children",
    //     t("Sum of max adult and max children should not exceed max occupancy."),
    //     function (value) {
    //       const { max_children = 0, max_occupancy } = this.parent;
    //       return (value || 0) + max_children <= max_occupancy;
    //     }
    //   ),
    max_adult: Yup.number().required(t("Max adult cannot be blank.")),
    // max_children: Yup.number()
    //   .optional()
    //   .test(
    //     "max-adult-plus-max-children",
    //     t("Sum of max adult and max children should not exceed max occupancy."),
    //     function (value) {
    //       const { max_adult = 0, max_occupancy } = this.parent;
    //       return max_adult + (value || 0) <= max_occupancy;
    //     }
    //   ),
    max_children: Yup.number().required(t("Max children cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    room_type_short_code: Yup.string().required(
      t("Room Code cannot be blank.")
    ),
    beds_info: Yup.array(
      Yup.object({
        bed_type: Yup.number().required(t("Bed Type cannot be blank")),
        width: Yup.string().optional(),
        length: Yup.string().optional(),
        mattress_type: Yup.number().required(
          t("Mattress type cannot be blank")
        ),
        quantity: Yup.number().required(t("Quantity cannot be blank.")),
      })
    ),
    room_facilities: Yup.array().min(1, t("Select atleast one facility")),
    base_category_multiplier: Yup.number()
      .min(0, t("Please enter more then 0"))
      .max(100, t("Please enter less then 100")),
  });

export const EditRoomTypeValidator = (t) =>
  Yup.object().shape({
    category_name: Yup.string().required(t("Name cannot be blank.")),
    // room_type: Yup.number().required("Type cannot be blank."),
    area: Yup.number().optional(),
    min_rate: Yup.number()
      .min(0, t("Please enter more then 1"))
      .required(t("Min Rate cannot be blank.")),
    max_rate: Yup.number()
      .min(1, t("Please enter more then 1"))
      .required(t("Max Rate cannot be blank."))
      .test(
        "max-rate-greater-than-min-rate",
        t("Max Rate must be greater than or equal to Min Rate."),
        function (value) {
          const { min_rate } = this.parent; // Access the value of min_rate from the same object
          return value >= min_rate; // Ensure max_rate is greater than or equal to min_rate
        }
      ),
    max_occupancy: Yup.number()
      .required(t("Max occupancy cannot be blank."))
      .test(
        "max-occupancy-greater-than-default",
        t("Max occupancy must be greater than or equal to default occupancy."),
        function (value) {
          const { default_occupancy } = this.parent; // Access default_occupancy
          return value >= default_occupancy; // Ensure max_occupancy is greater than or equal to default_occupancy
        }
      ),
    default_occupancy: Yup.number().required(
      t("Min occupancy cannot be blank.")
    ),
    // max_adult: Yup.number().optional().test(
    //   "max-adult-plus-max-children",
    //   t("Sum of max adult and max children should not exceed max occupancy."),
    //   function (value) {
    //     const { max_children = 0, max_occupancy } = this.parent;
    //     return (value || 0) + max_children <= max_occupancy;
    //   }
    // ),
    max_adult: Yup.number().required(t("Max adult cannot be blank.")),
    // max_children: Yup.number().optional().test(
    //   "max-adult-plus-max-children",
    //   t("Sum of max adult and max children should not exceed max occupancy."),
    //   function (value) {
    //     const { max_adult = 0, max_occupancy } = this.parent;
    //     return max_adult + (value || 0) <= max_occupancy;
    //   }
    // ),
    max_children: Yup.number().required(t("Max children cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    room_type_short_code: Yup.string().required(
      t("Room Code cannot be blank.")
    ),
    beds_info: Yup.array(
      Yup.object({
        bed_type: Yup.number().required(t("Bed Type cannot be blank")),
        mattress_type: Yup.number().required(
          t("Mattress type cannot be blank")
        ),
        quantity: Yup.number().required(t("Quantity cannot be blank.")),
      })
    ),
    room_facilities: Yup.array().min(1, t("Select atleast one facility")),
    base_category_multiplier: Yup.number()
      .min(0, t("Please enter more then 0"))
      .max(100, t("Please enter less then 100")),
  });

export const AddRoomsValidator = (t) =>
  Yup.object().shape({
    from: Yup.number().required(t("From cannot be blank.")),
    to: Yup.number().required(t("To cannot be blank.")),
    category_type: Yup.number().required(t("Type cannot be blank.")),
    floor_no: Yup.number().required(t("Floor number cannot be blank.")),
  });

export const CreateRoomsValidator = (t) =>
  Yup.object().shape({
    rooms_info: Yup.array(
      Yup.object({
        room_number: Yup.string().required(t("Room number cannot be blank")),
        room_category_id: Yup.number().required(t("Type cannot be blank.")),
        floor_number: Yup.number().required(t("Floor number cannot be blank")),
      })
    ),
  });

export const CreateSingleRoomValidator = (t) =>
  Yup.object().shape({
    room_number: Yup.string().required(t("Room number cannot be blank.")),
    room_type: Yup.number().required(t("Type cannot be blank.")),
    area: Yup.number().required(t("Area cannot be blank.")),
    // max_occupancy: Yup.number().optional(),
    floor_number: Yup.number().required(t("Floor number cannot be blank")),
    // connecting_door: Yup.number().required("Connecting cannot be blank."),
    description: Yup.string().required(t("Description cannot be blank.")),
    room_facilities: Yup.array().min(1, t("Select atleast one facility")),
  });

export const CreateSingleOtaValidator = (t) =>
  Yup.object().shape({
    ota_code: Yup.string().required(t("OTA Code cannot be blank.")),
    ota_name: Yup.string().required(t("OTA name cannot be blank.")),
    status: Yup.number().required(t("Status cannot be blank.")),
  });

export const UpdateSingleRoomValidator = (t) =>
  Yup.object().shape({
    room_number: Yup.string().required(t("Name cannot be blank.")),
    room_type: Yup.number().required(t("Type cannot be blank.")),
    area: Yup.number().required(t("Area cannot be blank.")),
    // max_occupancy: Yup.number().required("Max occupancy cannot be blank."),
    floor_number: Yup.number().required(t("Floor number cannot be blank")),
    description: Yup.string().required(t("Description cannot be blank.")),
    room_facilities: Yup.array().min(1, t("Select atleast one facility")),
  });

export const CreateSeasonalRoomRateValidator = (t) =>
  Yup.object().shape({
    category_rate_type_id: Yup.number().required(
      t("Rate type cannot be blank.")
    ),
    name: Yup.string().required(t("Name cannot be blank.")),
    start_date: Yup.date().required(t("Start date cannot be blank.")),
    end_date: Yup.date().required(t("End date cannot be blank.")),
  });
export const EditSeasonalRoomRateValidator = (t) =>
  Yup.object().shape({
    category_rate_type_id: Yup.number().required(
      t("Rate type cannot be blank.")
    ),
    name: Yup.string().required(t("Name cannot be blank.")),
    start_date: Yup.date().required(t("Start date cannot be blank.")),
    end_date: Yup.date().required(t("End date cannot be blank.")),
  });

// Yup.addMethod(
//   Yup.array,
//   "uniqueRoomNumbers",
//   function (message, mapper = (a) => a) {
//     return this.test("unique", message, function (list) {
//       return list.length === new Set(list.map(mapper)).size;
//     });
//   }
// );

// Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
//   return this.test("unique", message, function (list) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });
// id_proof-1677821704174.png
export const CheckInValidations = (t) =>
  Yup.object().shape({
    check_in_time: Yup.string().required(t("Check In Time Is Required")),
    // id_proof_img: Yup.mixed().required("Id Proof Is Required"),
    // id_proof: Yup.mixed().required("Id Proof Is Required"),
    id_proof: Yup.mixed().when("id_proof_img", {
      is: (id_proof_img) => id_proof_img == null,
      then: Yup.mixed().required(t("Id Proof Is Required")),
      otherwise: Yup.string().optional().nullable(),
    }),
    id_proof_type: Yup.number()
      .min(1, t("Id Proof Type Is Required"))
      .required(t("Id Proof Type Is Required")),
    // room_info: Yup.array(
    //   Yup.object({
    //     item_id: Yup.number().required("required"),
    //     room_number: Yup.number()
    //       .min(1, "Room Number Is Required")
    //       .required("Room Number Is Required"),
    //     // .unique("Room must be unique"),
    //   })
    // ),
  });

export const CreateRoomRates = (t) =>
  Yup.object().shape({
    rate_name: Yup.string().required(t("Name cannot be blank.")),
    // meal_price: Yup.number().required("Meal Price cannot be blank."),
    cancellation_policy: Yup.number().required(
      t("Cancellation cannot be blank.")
    ),
    prices: Yup.array(
      Yup.object({
        room_category_id: Yup.number(),
        pricing: Yup.array(
          Yup.object({
            accomodation_id: Yup.string(),
            // is_default_price_for_all_days: Yup.boolean().required(
            //   "is default price cannot be blank."
            // ),
            rate: Yup.number()
              .min(0, t("Rate can not be less then 0"))
              .required(t("Default Price cannot be blank")),
            // monday_price: Yup.number().min(0).required("Monday Price cannot be blank"),
            // tuesday_price: Yup.number().required("Tuesday Price cannot be blank"),
            // wednesday_price: Yup.number().required(
            //   "Wednesday Price cannot be blank"
            // ),
            // thursday_price: Yup.number().required(
            //   "Thursday Price cannot be blank"
            // ),
            // friday_price: Yup.number().required("Friday Price cannot be blank"),
            // saturday_price: Yup.number().required(
            //   "Saturday Price cannot be blank"
            // ),
            // sunday_price: Yup.number().required("Sunday Price cannot be blank"),
          })
        ),
      })
    ),
  });
export const AddBookingGuestInfoValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    phone: Yup.number().required(t("Phone cannot be blank.")),
    email: Yup.string()
      .email(t("Not a proper email"))
      .required(t("Email cannot be blank.")),
    id_proof_type: Yup.number().required(t("Id proof type cannot be blank")),
    id_number: Yup.string(),
    id_proof: Yup.mixed().required(t("Id proof cannot be blank")),
    address: Yup.string().required(t("Address cannot be blank")),
    city: Yup.string().required(t("City cannot be blank.")),
    country: Yup.string().required(t("Country cannot be blank.")),
    state: Yup.string().required(t("State cannot be blank.")),
    pin_code: Yup.string().required(t("Postel Code cannot be blank.")),
    tax_exempt_num: Yup.number(),
  });

export const AgreeBookingGuestInfoValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    phone: Yup.number().required(t("Phone cannot be blank.")),
    email: Yup.string().email(t("Not a proper email")),
    // .required("Email cannot be blank."),
    //   id_proof_type: Yup.number().required("Id proof type cannot be blank"),
    //   id_number: Yup.string(),
    //   id_proof: Yup.mixed().test("type", "Only the following formats are accepted: .jpeg, .jpg, .png", (value) => {
    //     return value && (
    //         value[0].type === "image/jpeg" ||
    //         value[0].type === "image/jpg" ||
    //         value[0].type === "image/png"
    //     );
    // }),
    address: Yup.string(),
    // .required("Address cannot be blank"),
    city: Yup.string(),
    // .required("City cannot be blank."),
    country: Yup.string(),
    // .required("Country cannot be blank."),
    state: Yup.string(),
    // .required("State cannot be blank."),
    pin_code: Yup.string(),
    // .required("Postel Code cannot be blank."),
    tax_exempt_num: Yup.number(),
  });

export const ImportBookingCloudbedsValidator = (t) =>
  Yup.object().shape({
    id_proof: Yup.mixed().required(t("First Upload CSV file")),
  });

export const ImportBookingValidator = (t) =>
  Yup.object().shape({
    id_proof: Yup.mixed().required(t("First Upload CSV file")),
    season_id: Yup.number()
      .min(1, t("Please Select Season Rate"))
      .required(t("Please Select Season Rate ")),
  });

export const AddCouponValidator = (t) =>
  Yup.object().shape({
    coupon_name: Yup.string().required(t("Coupon Name cannot be blank.")),
    coupon_code: Yup.string()
      .test(
        "coupon_code",
        t("Enter Valid Coupon Code"),
        (value) => !value?.includes(" ")
      )
      .required(t("Coupon Code cannot be blank.")),
    coupon_value: Yup.number()
      .min(0, t("Please enter more then 0"))
      .max(100, t("Please enter less then 100"))
      .required(t("Coupon Value cannot be blank.")),
  });

export const ImportOtaBookingValidator = (t) =>
  Yup.object().shape({
    id_proof: Yup.mixed().required(t("First Upload CSV file")),
    // ota_code: Yup.number().required("Select OTA Code"),
    rateid: Yup.number().required(t("Select Rate Plan Id")),
  });

export const ImportMinStayValidator = (t) =>
  Yup.object().shape({
    id_proof: Yup.mixed().required(t("First Upload CSV file")),
    // ota_code: Yup.number().required("Select OTA Code"),
    rateid: Yup.number().required(t("Select Rate Plan Id")),
  });

export const SendMessage = (t) =>
  Yup.object().shape({
    phone_number: Yup.number().required(t("Phone cannot be blank.")),
    sms: Yup.string().required(t("Message cannot be blank")),
  });

export const SendEmail = (t) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("Not a proper email"))
      .required(t("Email cannot be blank.")),
    body: Yup.string().required(t("Message cannot be blank")),
    subject: Yup.string().required(t("Subject cannot be blank")),
  });

export const EditEmailTemplateValidator = (t) =>
  Yup.object().shape({
    body: Yup.string().required(t("Message cannot be blank")),
    subject: Yup.string().required(t("Subject cannot be blank")),
  });

export const EditAgreementTemplateValidator = (t) =>
  Yup.object().shape({
    agreement_acceptance: Yup.string().required(t("Message cannot be blank")),
    general_release: Yup.string().required(t("Subject cannot be blank")),
    credit_card_authorization: Yup.string().required(
      t("Subject cannot be blank")
    ),
  });

export const EditSmsTemplateValidator = (t) =>
  Yup.object().shape({
    body: Yup.string().required(t("Message cannot be blank")),
  });

export const AddProductTypeValidator = (t) =>
  Yup.object().shape({
    type: Yup.string().required(t("Type cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    kitchen_section_id: Yup.string().required(
      t("Kitchen types cannot be blank.")
    ),
  });
export const CreateBookingRoomRestrictionValidator = (t) =>
  Yup.object().shape({
    room_id: Yup.array(),
    start_date: Yup.date().required(t("Start date cannot be blank.")),
    end_date: Yup.date().required(t("End date cannot be blank.")),
  });

export const EditBookingRoomRestrictionValidator = (t) =>
  Yup.object().shape({
    room_id: Yup.number(),
    start_date: Yup.date().required(t("Start date cannot be blank.")),
    end_date: Yup.date().required(t("End date cannot be blank.")),
  });

export const EditCancellationPolicyValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    cancellation_policy_criterias: Yup.array(
      Yup.object({
        time_period_value: Yup.number().required(
          t("Time Value cannot be blank")
        ),
        refund_type_value: Yup.number().when("refund_type", {
          is: (refund_type) => +refund_type === 1,
          then: Yup.number()
            .min(0, t("Please enter more then 0"))
            .max(100, t("Please enter less then 100"))
            .required(t("Refund Value cannot be blank")),
          otherwise: Yup.number().required(t("Refund Value cannot be blank")),
        }),
      })
    ),
  });

export const EditPosTableValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
  });

export const EditCleaningListValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
  });

export const Tax = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    tax_rate: Yup.number().required(t("Tax Rate cannot be blank.")),
    is_exempt_tax: Yup.number().required(t("Is Exempt Tax cannot be blank.")),
  });

export const AddCompanyValidator = (t) =>
  Yup.object().shape({
    company_profile_name: Yup.string().required(
      t("Comapany name cannot be blank.")
    ),
    postal_code: Yup.string().required(t("Postal code cannot be blank.")),
    phone: Yup.string().required(t("Phone number cannot be blank.")),
    email: Yup.string().email().required(t("Room Nights cannot be blank.")),
    notes: Yup.string().required(t("Notes cannot be blank.")),
    contact_title: Yup.string().required(t("Contact Title cannot be blank.")),
    contact_name: Yup.string().required(t("Contact Name cannot be blank.")),
    fax: Yup.string().required(t("Fax cannot be blank.")),
    city: Yup.string().required(t("City cannot be blank.")),
    address: Yup.string().required(t("Address Name cannot be blank.")),
    country_id: Yup.string().required(t("Country cannot be blank.")),
    state_id: Yup.string().required(t("State cannot be blank.")),
    phone_code: Yup.string().required(t("phone_code cannot be blank.")),
    language: Yup.string().required(t("Language cannot be blank.")),
    default_rate_plan_id: Yup.string().required(
      t("Default rate plan cannot be blank.")
    ),
  });

export const CreateGroupBookingValidator = (t) =>
  Yup.object().shape({
    first_name: Yup.string().required(t("Frist Name cannot be blank.")),
    iata_number: Yup.string().optional(),
    last_name: Yup.string().required(t("Last Name cannot be blank.")),
    company_name: Yup.string().required(t("Company Name cannot be blank.")),
    deposit_amount: Yup.number().required(t("Deposit Amount cannot be blank.")),
    room_nights: Yup.number().required(t("Room Nights cannot be blank.")),
    room_descriptions: Yup.string().required(
      t("Room Description cannot be blank.")
    ),
    room_cost: Yup.number().required(t("Room Cost cannot be blank.")),
    pos_cost: Yup.number().required(t("Pos Cost cannot be blank.")),
    taxes: Yup.number().required(t("Taxes cannot be blank.")),
    total_cost: Yup.number().required(t("Total Cost cannot be blank.")),
    house_account: Yup.string().required(t("House Account cannot be blank.")),
    incidental_invoice_credit_limit: Yup.number().required(
      t("Incidental Invoice Credit Limit cannot be blank.")
    ),
    access_code: Yup.string().required(t("Access Code cannot be blank.")),
    address: Yup.string().required(t("Address cannot be blank.")),
    city: Yup.string().required(t("City cannot be blank.")),
    email: Yup.string().email().required(t("Email is required")),
  });

export const AddVendorValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Vendor Name cannot be blank.")),
    company_name: Yup.string().required(t("Company Name cannot be blank.")),
    description: Yup.string().required(t("Description Name cannot be blank.")),
    category: Yup.number()
      .min(1, t("Please select category."))
      .required(t("Please select category")),
    poc: Yup.string().required(t("Poc cannot be blank.")),
    country: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    postal_code: Yup.string(),
    country_code: Yup.string().required(t("Country code cannot be blank.")),
    address: Yup.string(),
    mobile: Yup.number().required(t("Mobile Number cannot be blank.")),
    email: Yup.string().email().required(t("Email cannot be blank.")),
  });

export const CreatePOorderValidator = (t) =>
  Yup.object().shape({
    vendor_id: Yup.number()
      .min(1, t("Please select Vendor."))
      .required(t("Please Select Vendor")),
  });

export const AddProductValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Vendor Name cannot be blank.")),
    product_type: Yup.string().required(t("Product Type cannot be blank.")),
    cost_price: Yup.number().required(t("Cost Price cannot be blank.")),
    selling_price: Yup.number().required(t("Selling Price cannot be blank.")),
    quantity: Yup.number().required(t("Quantity cannot be blank.")),
    product_category: Yup.number().required(
      t("Product category cannot be blank.")
    ),
    unit: Yup.number().required(t("Unit cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
  });

export const AddPropertyMaintenanceValidator = (t) =>
  Yup.object().shape({
    room_id: Yup.string().required(t("Room cannot be blank.")),
    remark: Yup.string().required(t("Remark cannot be blank.")),
    // image: Yup.mixed()
    // .nullable()
    // .test(
    //   "fileRequired",
    //   t("Image is required."),
    //   (value) => value instanceof File // Ensures a file object is present
    // ),
  });

export const EditProductValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Vendor Name cannot be blank.")),
    product_type: Yup.string().required(t("Product Type cannot be blank.")),
    cost_price: Yup.number().required(t("Cost Price cannot be blank.")),
    selling_price: Yup.number().required(t("Selling Price cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    product_category: Yup.number().required(
      t("Product category cannot be blank.")
    ),
  });

export const AddAdjustStock = (t) =>
  Yup.object().shape({
    product_id: Yup.number()
      .min(1, "Product cannot be blank.")
      .required(t("Product cannot be blank.")),
    unit: Yup.number().required(t("Unit Price cannot be blank.")),
    quantity: Yup.number().required(t("Quantity  cannot be blank.")),
    note: Yup.string().required(t("Note cannot be blank.")),
  });

export const AddAddOnValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Vendor Name cannot be blank.")),
    product_type: Yup.string().required(t("Product Type cannot be blank.")),
    cost_price: Yup.number().required(t("Cost Price cannot be blank.")),
    selling_price: Yup.number().required(t("Selling Price cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
  });

export const AddDiscountRateValidator = (t) =>
  Yup.object().shape({
    code: Yup.string().required(t("Code cannot be blank.")),
    name: Yup.string().required(t("Name cannot be blank.")),
    percentage: Yup.number()
      .min(0, t("percentage Is min 0"))
      .max(100, t("percentage is max 100 "))
      .required(t("percentage cannot be blank.")),
  });

export const AddGroupInformationValidator = (t) =>
  Yup.object().shape({
    group_name: Yup.string().required(t("Group Name cannot be blank.")),
    contact_name: Yup.string().required(
      t("Contact last Name cannot be blank.")
    ),
    postal_code: Yup.string().required(t("Postal Code cannot be blank.")),
    phone: Yup.number().required(t("Number cannot be blank.")),
    guest_tracking: Yup.string().required(t("Guest Tracking cannot be blank.")),
    market: Yup.string().required(t("Market cannot be blank.")),
    email: Yup.string().email().required(t("Email cannot be blank.")),
    charge_routing: Yup.number().required(t("Charge Routing cannot be blank.")),
    iata_number: Yup.number().required(t("Iata Number cannot be blank.")),
    company_profile_name: Yup.string().required(
      t("Company Profile Name cannot be blank.")
    ),
    city: Yup.string().required(t("City cannot be blank.")),
    address: Yup.string().required(t("Address Name cannot be blank.")),
    country_id: Yup.string().required(t("Country cannot be blank.")),
    state_id: Yup.string().required(t("State cannot be blank.")),
    language_id: Yup.string().required(t("Language cannot be blank.")),
  });
export const changeLockNameValidator = (t) =>
  Yup.object().shape({
    lock_name: Yup.string().required(t("Name cannot be blank.")),
  });
export const changeLockTimeValidator = (t) =>
  Yup.object().shape({
    time: Yup.number().min(1).max(120).required(t("Time cannot be blank.")),
  });

export const AddGuestInfoValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    phone: Yup.number().required(t("number cannot be blank.")),
    email: Yup.string().email().required(t("Email cannot be blank.")),
  });

export const AddOn = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    amount: Yup.number().required(t("Amount cannot be blank.")),
  });

export const OccCri = (t) =>
  Yup.object().shape({
    type: Yup.string().required(t("Type cannot be blank.")),
    value: Yup.number()
      .min(0, t("Amount should not less 0"))
      .required(t("Amount cannot be blank.")),
    increase_decrease: Yup.number().required(t("Rule cannot be blank.")),
  });

export const SpeCri = (t) =>
  Yup.object().shape({
    type: Yup.string().required(t("Type cannot be blank.")),
    value: Yup.number()
      .min(0, t("Amount should not less 0"))
      .required(t("Amount cannot be blank.")),
    increase_decrease: Yup.number().required(t("Rule cannot be blank.")),
  });

export const MinStayCri = (t) =>
  Yup.object().shape({
    min_stay_length: Yup.number()
      .min(0, t("Length should not less 0"))
      .required(t("Length cannot be blank.")),
  });

export const popular_place = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    distance: Yup.string().required(t("Distance cannot be blank.")),
  });

export const CreateDestinationValidator = (t) =>
  Yup.object().shape({
    destination_name: Yup.string().required(
      t("Destination Name cannot be blank.")
    ),
    // destination_logo: Yup.string().required(t("Destination logo cannot be blank.")),
    destination_description: Yup.string().required(
      t("Description cannot be blank.")
    ),
    latitude: Yup.number().required(t("Latitude cannot be blank.")),
    longitude: Yup.number().required(t("Longitude cannot be blank.")),
    country: Yup.string().required(t("Country cannot be blank.")),
    state: Yup.string().required(t("State cannot be blank.")),
    address: Yup.string().required(t("Address cannot be blank.")),
    city: Yup.string().required(t("City cannot be blank.")),
    postal_code: Yup.string().required(t("Postel Code cannot be blank.")),
  });

export const CreateOwnerValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    email: Yup.string().email().required(t("email cannot be blank.")),
    password: Yup.string().required(t("password cannot be blank.")),
    phone_code: Yup.string().required(t("phone_code cannot be blank.")),
    mobile: Yup.number().required(t("Mobile cannot be blank.")),
  });

export const EditOwnerValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    email: Yup.string().email().required(t("email cannot be blank.")),
    password: Yup.string(),
    phone_code: Yup.string().required(t("phone_code cannot be blank.")),
    mobile: Yup.number().required(t("Mobile cannot be blank.")),
  });

export const CreateModuleValidator = (t) =>
  Yup.object().shape({
    module_name: Yup.string().required(t("Module Name cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
  });

export const CreateRoomTypesValidator = (t) =>
  Yup.object().shape({
    hotelID: Yup.string().required(t("Hotel ID cannot be blank.")),
    maxoccupancy: Yup.string().required(t("Max occupancy cannot be blank.")),
    maxchildoccupancy: Yup.string().required(
      t("Max child occupancy cannot be blank.")
    ),
    roomID: Yup.string().required(t("Room ID cannot be blank.")),
    roomRate: Yup.number().required(t("Room Rate cannot be blank.")),
    quantity: Yup.number().required(t("Quntity cannot be blank.")),
    roomType: Yup.string().required(t("Room Type cannot be blank.")),
    sizeMeasurement: Yup.number().required(
      t("Size Measurement Type cannot be blank.")
    ),
    sizeMeasurementUnit: Yup.string().required(
      t("Size Measurement Type cannot be blank.")
    ),
    latitude: Yup.string().optional(),
    longitude: Yup.string().optional(),
    addressLine1: Yup.string().optional(),
    cityName: Yup.string().optional(),
    countryName: Yup.string().optional(),
    postalCode: Yup.string().optional(),
    roomDescription: Yup.string().required(
      t("Room description text cannot be blank.")
    ),
    descriptionText: Yup.string().required(
      t("Room description text cannot be blank.")
    ),
  });

export const CreateRatePlanValidator = (t) =>
  Yup.object().shape({
    RatePlanNotifType: Yup.string().required(
      t("Rate Plan Notification type cannot be blank.")
    ),
    rateplanid: Yup.string().required(t("Rate Plan ID type cannot be blank.")),
    MealPlanID: Yup.string().optional(),
    roomDescription: Yup.string().required(
      t("Room Description cannot be blank.")
    ),
    descriptionText: Yup.string().required(
      t("Description text cannot be blank.")
    ),
  });

export const PropertyNew = (t) =>
  Yup.object().shape({
    hotelId: Yup.string().required(t("Hotel ID cannot be blank.")),
    HotelName: Yup.string().required(t("Hotel Name cannot be blank.")),
    LanguageCode: Yup.string().required(t("Language Code cannot be blank.")),
    CurrencyCode: Yup.string().required(t("Currency Code cannot be blank.")),
    ContactInfos: Yup.object().shape({
      ContactProfileType: Yup.string().required(
        t("ContactProfileType cannot be blank.")
      ),
      Emails: Yup.string().required(t("Emails cannot be blank.")),
      Addresses: Yup.object().shape({
        AddressLine: Yup.string().required(t("Address Line cannot be blank.")),
        CityName: Yup.string().required(t("City Name cannot be blank.")),
        PostalCode: Yup.number().required(t("Postal Code cannot be blank.")),
        CountryName: Yup.string().required(t("Country Name cannot be blank.")),
      }),
      Names: Yup.object().shape({
        GivenName: Yup.string().required(t("Given Name cannot be blank.")),
        Surname: Yup.string().required(t("Surname cannot be blank.")),
      }),
      Phones: Yup.object().shape({
        PhoneNumber: Yup.number().required(t("Phone Number cannot be blank.")),
        PhoneTechType: Yup.number().required(
          t("Phone Tech Type cannot be blank.")
        ),
      }),
    }),

    HotelInfo: Yup.object().shape({
      Position: Yup.string().required(t("Position cannot be blank.")),
      Longitude: Yup.number()
        .min(-180, t("Latitude cann't less the to -180"))
        .max(180, t("Latitude cann't greater then to 180"))
        .required(t("Longitude cannot be blank.")),
      Latitude: Yup.number()
        .min(-90, t("Latitude cann't less the to -90"))
        .max(90, t("Latitude cann't greater then to 90"))
        .required(t("Latitude cannot be blank.")),
    }),
  });

export const AdminUsers = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    mobile: Yup.number().required(t("Mobile Number cannot be blank.")),
    email: Yup.string().email().required(t("Email cannot be blank.")),
    password: Yup.string().required(t("Password cannot be blank.")),
    role_id: Yup.number().required(t("Role cannot be blank.")),
  });

export const AdminUsersEdit = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    mobile: Yup.number().required(t("Mobile Number cannot be blank.")),
    email: Yup.string().email().required(t("Email cannot be blank.")),
    role_id: Yup.number().required(t("Role cannot be blank.")),
  });

export const CreateRatesAndAvailabilityValidator = (t) =>
  Yup.object().shape({
    hotelID: Yup.string().required(t("Hotel ID cannot be blank.")),
    room_info: Yup.array(
      Yup.object({
        roomid: Yup.string().required(t("Room ID cannot be blank")),
        sendAllData: Yup.number().required(t("Select Data cannot be blank.")),
        showDate: Yup.number().required(t("Date Type cannot be blank.")),
        rate: Yup.array().when("sendAllData", {
          is: (sendAllData) => sendAllData === 1 || sendAllData === 3,
          then: Yup.array(
            Yup.object({
              rateplanid: Yup.string().required(
                t("Rateplan ID cannot be blank")
              ),
            })
          ),
          otherwise: Yup.array(),
        }),
        roomstosell: Yup.number().when("sendAllData", {
          is: (sendAllData) => sendAllData !== 3,
          then: Yup.number()
            .min(0, t("Room to sale cann't less the to 1"))
            .required(t("Room to sale cannot be blank.")),
          otherwise: Yup.number(),
        }),
        price: Yup.array().when("sendAllData", {
          is: (sendAllData) => sendAllData === 1 || sendAllData === 3,
          then: Yup.array(
            Yup.object({
              NumberOfGuests: Yup.string().required(
                t("No Of Guest cann't be blank")
              ),
              value: Yup.string().required(t("Price cann't be blank")),
            })
          ),
          otherwise: Yup.array(),
        }),
        closed: Yup.number(),
        minimumstay: Yup.number()
          .min(0, t("Minimum stay cann't less the to 0"))
          .when("sendAllData", {
            is: (sendAllData) => sendAllData === 1,
            then: Yup.number().required(t("Minimum Stay cannot be blank")),
            otherwise: Yup.number(),
          }),
        maximumstay: Yup.number()
          .min(0, t("Maximum stay cann't less the to 0"))
          .when("sendAllData", {
            is: (sendAllData) => sendAllData === 1,
            then: Yup.number().required(t("Maximum Stay cannot be blank")),
            otherwise: Yup.number(),
          }),
        closedonarrival: Yup.number(),
        extraadultrate: Yup.number()
          .min(0, t("Extra Adult cann't less the to 0"))
          .when("sendAllData", {
            is: (sendAllData) => sendAllData === 1,
            then: Yup.number().required(t("Extra Adult cannot be blank")),
            otherwise: Yup.number(),
          }),
        extrachildrate: Yup.number()
          .min(0, t("Extra Child cann't less the to 0"))
          .when("sendAllData", {
            is: (sendAllData) => sendAllData === 1,
            then: Yup.number().required(t("Extra Child cannot be blank")),
            otherwise: Yup.number(),
          }),
        closedondeparture: Yup.number(),
      })
    ),
    // roomid: Yup.string().required("Room ID cannot be blank")
  });

export const CreateRatesAccordingOtasValidator = (t) =>
  Yup.object().shape({
    hotelid: Yup.string().required(t("Hotel ID cannot be blank.")),
    ratecontrol: Yup.array(
      Yup.object({
        roomid: Yup.string().required(t("Room ID cannot be blank")),
        rateid: Yup.string().required(t("Rate ID cannot be blank")),
        date: Yup.array(
          Yup.object({
            from: Yup.string().required(t("From cannot be blank.")),
            to: Yup.string().required(t("To cannot be blank.")),
            OTARule: Yup.array(
              Yup.object({
                OTACode: Yup.array().min(1, "Select atleast one OTA Code"),
                rule: Yup.object({
                  type: Yup.string().required(t("Rule Type cannot be blank.")),
                  value: Yup.string().required(
                    t("Rule Value cannot be blank.")
                  ),
                }),
              })
            ),
          })
        ),
      })
    ),
    // roomid: Yup.string().required(t("Room ID cannot be blank)")
  });

export const CreateAvailibilityAccordingOtasValidator = (t) =>
  Yup.object().shape({
    hotelid: Yup.string().required(t("Hotel ID cannot be blank.")),
    inventorycontrol: Yup.array(
      Yup.object({
        roomid: Yup.string().required(t("Room ID cannot be blank")),
        date: Yup.array(
          Yup.object({
            from: Yup.string().required(t("From cannot be blank.")),
            to: Yup.string().required(t("To cannot be blank.")),
            OTARule: Yup.array(
              Yup.object({
                OTACode: Yup.array().min(1, "Select atleast one OTA Code"),
                rule: Yup.object({
                  type: Yup.string().required(t("Rule Type cannot be blank.")),
                  value: Yup.string().required(
                    t("Rule Value cannot be blank.")
                  ),
                }),
              })
            ),
          })
        ),
      })
    ),
  });

export const RemoveAvailibilityAccordingOtasValidator = (t) =>
  Yup.object().shape({
    hotelid: Yup.string().required(t("Hotel ID cannot be blank.")),
    inventorycontrol: Yup.array(
      Yup.object({
        roomid: Yup.string().required(t("Room ID cannot be blank")),
        date: Yup.array(
          Yup.object({
            from: Yup.string().required(t("From cannot be blank.")),
            to: Yup.string().required(t("To cannot be blank.")),
          })
        ),
      })
    ),
  });

export const RemoveRatesAccordingOtasValidator = (t) =>
  Yup.object().shape({
    hotelid: Yup.string().required(t("Hotel ID cannot be blank.")),
    ratecontrol: Yup.array(
      Yup.object({
        roomid: Yup.string().required(t("Room ID cannot be blank")),
        rateid: Yup.string().required(t("Rate ID cannot be blank")),
        date: Yup.array(
          Yup.object({
            from: Yup.string().required(t("From cannot be blank.")),
            to: Yup.string().required(t("To cannot be blank.")),
          })
        ),
      })
    ),
    // roomid: Yup.string().required(t("Room ID cannot be blank)")
  });

export const ShiftValidation = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    shift_number: Yup.string().required(t("Shift Number cannot be blank.")),
    start_time: Yup.string().required(t("Start time cannot be blank.")),
    end_time: Yup.string().required(t("End time cannot be blank.")),
  });

export const roles = (t) =>
  Yup.object().shape({
    role_name: Yup.string().required(t("Role Name cannot be blank.")),
  });

export const CreateAttractionValidator = (t) =>
  Yup.object().shape({
    attraction_name: Yup.string().required(t(" Name cannot be blank.")),
    description: Yup.string().required(t("Description cannot be blank.")),
    link: Yup.string().required(t("Link cannot be blank.")),
    lat: Yup.number().required(t("Latitude cannot be blank.")),
    long: Yup.number().required(t("Longitude cannot be blank.")),
    startDate: Yup.date().required(t("Start date cannot be blank.")),
    endDate: Yup.date().required(t("End date cannot be blank.")),
    address: Yup.string().required(t("Address cannot be blank.")),
    country: Yup.string().required(t("Country cannot be blank.")),
    state: Yup.string().required(t("State cannot be blank.")),
    city: Yup.string().required(t("City cannot be blank.")),
    postal_code: Yup.string().required(t("Postal Code cannot be blank.")),
    type: Yup.number()
      .required(t("Attraction Type cannot be blank."))
      .oneOf([1, 2], t("Please select a valid type.")), // Ensures only 1 or 2 are valid
  });

export const createMiscellaneousChargesValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    note: Yup.string().required(t("Note cannot be blank.")),
    amount: Yup.number()
      .typeError(t("Amount must be a number.")) // Ensures type validation
      .positive(t("Amount must be positive."))
      .required(t("Amount cannot be blank.")),
    type: Yup.number()
      .required(t("Type cannot be blank."))
      .oneOf([1, 2], t("Please select a valid type.")), // Ensures only 1 or 2 are valid
  });

export const createRecurringChargesValidator = (t) =>
  Yup.object().shape({
    name: Yup.string().required(t("Name cannot be blank.")),
    note: Yup.string().required(t("Note cannot be blank.")),
    amount: Yup.number()
      .typeError(t("Amount must be a number.")) // Ensures type validation
      .positive(t("Amount must be positive."))
      .required(t("Amount cannot be blank.")),
    charge_when: Yup.number()
      .required(t("Charge Period cannot be blank."))
      .oneOf([1, 2], t("Please select a valid Charge Period.")), // Ensures only 1 or 2 are valid
  });

export const createOperatingExpensesValidator = (t) =>
  Yup.object().shape({
    note: Yup.string().optional(),
    amount: Yup.number()
      .typeError(t("Amount must be a number.")) // Ensures type validation
      .positive(t("Amount must be positive."))
      .required(t("Amount cannot be blank.")),
    operating_type: Yup.number()
      .required(t("Type cannot be blank."))
      .oneOf([1, 2, 3, 4], t("Please select a valid type.")), // Ensures only 1 or 2 are valid
  });
